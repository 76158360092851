// timings to remind expiration of discovery offers, in days
export const expirationReminders = [13, 7, 1];

export const soundUiByDefault = true;
export const soundAtmosByDefault = false;

// the application always contains all available languages and may autodetect
// and store one of the supported languages at the first run, even if it's
// not part of the languages requested by the customer's configuration
// this setting allows to force the default language specified by the backend
// on the first connection
export const forceDefaultLanguageOnFirstConnection = true;

// display of language selection on first connection
export const selectLanguageOnFirstConnection = false;

// if the standard search algorithm returns no results in the current
// language, perform an extra search in the english game titles
export const extraEnglishSearch = false;

// allow usage on MacOS desktop
export const enableMacOS = true;

// show popup to suggest reset of initial password
export const resetPasswordOnFirstConnection = true;

// marketing editorials on highlights page
export const enableMarketingNews = true;

// use games' wallpapers as general app backgrounds
export const useGamesWallpapers = true;
