import { useGamesWallpapers } from 'app/config';
import { isHP } from 'app/device';
import { useThemeContext, withInputDispatcherProvider } from 'context';
import { MultiView, View } from 'context/multi-view';
import { assetURL } from 'helpers';
import { useActionTopPress, useHomePress } from 'hooks';
import { useToggleFavorite } from 'hooks/games/useToggleFavorite';
import { useCallback, useEffect } from 'react';
import { Games } from 'services';
import * as S from './GameWindow.style';
import { DescriptionView } from './views/description/DescriptionView';
import { MoreView } from './views/more/MoreView';
import { MultiChoiceView } from './views/multi/MultiChoiceView';
import { QuickMatchView } from './views/quickmatch/QuickMatchView';

export const GameWindow = withInputDispatcherProvider(
    ({ defaultView, ...props }) => {
        const { toggleGame } = useToggleFavorite();
        const { index, onClose, selectedIndex } = props;
        const { startWallpaperLoop } = useThemeContext();

        useHomePress(
            useCallback(() => {
                if (isHP) {
                    onClose();
                }
            }, [onClose])
        );

        // we may be rendering multiple windows for the swiper, some stuff needs to be done only for the
        // one currently being displayed
        const current = index === selectedIndex;

        const { alias } = props.game;

        useActionTopPress(
            useCallback(() => {
                if (!current || !Games.FavoritesEnabled()) {
                    return;
                }
                toggleGame(alias);
            }, [current, alias, toggleGame])
        );

        // start game-specific wallpaper loop
        const { wallpapers } = props.game.assets;

        useEffect(() => {
            if (!current || !useGamesWallpapers) {
                return;
            }

            const wallpaperURLs = wallpapers.map(assetURL);
            startWallpaperLoop({ wallpaperURLs, interval: 20 * 1000 });
        }, [alias, current, wallpapers, startWallpaperLoop]);

        // default view may be quickmatch, use only for the current window
        const view = (current && defaultView) || 'description';

        return (
            <S.Wrapper>
                <S.Window>
                    <MultiView defaultView={view}>
                        <View
                            name="description"
                            component={DescriptionView}
                            isCurrent={current}
                            {...props}
                        />
                        <View
                            name="multi"
                            component={MultiChoiceView}
                            {...props}
                        />
                        <View
                            name="quick-match"
                            component={QuickMatchView}
                            {...props}
                        />
                        <View
                            name="more-view"
                            component={MoreView}
                            {...props}
                        />
                    </MultiView>
                </S.Window>
            </S.Wrapper>
        );
    }
);
