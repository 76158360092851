import { If } from 'helpers';
import { useTranslation } from 'react-i18next';
import {
    IconMultiplayer,
    IconMultiplayer2,
    IconTouchscreen,
    IconVirtualGamepad,
} from '../icons';

import * as S from './Tags.style';

export function Tags({ game, isGrid, isCurrent }) {
    const { t } = useTranslation();
    // tags, in order of priority for display in description view
    const gameTags = [
        [
            'new',
            {
                priority: 0,
                value: game.release_status === 'new',
                hideValue: true,
                hideLabel: false,
            },
        ],
        [
            'coming_soon',
            {
                priority: 0,
                value: game.release_status === 'coming_soon',
                hideValue: true,
                hideLabel: false,
            },
        ],
        // [
        //     'quick-match',
        //     {
        //         priority: 0,
        //         value: game.nb_players_online_multi,
        //         icon: <IconQuickmatch />,
        //         hideLabel: isMobile,
        //     },
        // ],
        [
            'local',
            {
                priority: 0,
                value: game.nb_players_local_multi,
                icon:
                    game.nb_players_local_multi === 2 ? (
                        <IconMultiplayer />
                    ) : (
                        <IconMultiplayer2 />
                    ),
                hideLabel: true,
            },
        ],
        [
            '4k',
            {
                priority: 0,
                value: game.has4k,
                hideLabel: false,
            },
        ],
        [
            'touchscreen',
            {
                priority: 1,
                hideValue: true,
                hideLabel: true,
                value: game.category.includes('touchscreen'),
                icon: <IconTouchscreen />,
            },
        ],
        [
            'virtual_gamepad',
            {
                priority: 1,
                hideValue: true,
                hideLabel: true,
                value: game.category.includes('virtual_gamepad'),
                icon: <IconVirtualGamepad />,
            },
        ],
    ].sort((a, b) => b[1].priority - a[1].priority);

    const jsx = [];

    for (const [tagName, tagValue] of gameTags) {
        if (isGrid && tagName !== 'new') {
            // in grid mode display only "new" tag
            continue;
        }

        if (tagValue.value) {
            jsx.push(
                <S.Tag key={tagName} color={S.TAG[tagName]} isGrid={isGrid}>
                    <If condition={tagValue.icon}>
                        <S.IconWrapper
                            spacing={!tagValue.hideValue || !tagValue.hideLabel}
                        >
                            {tagValue.icon}
                        </S.IconWrapper>
                    </If>
                    <S.TextWrapper>
                        <If condition={!isGrid && !tagValue.hideValue}>
                            <S.MultiNumber>{tagValue.value}</S.MultiNumber>
                        </If>
                        <If condition={!tagValue.hideLabel}>
                            <S.TagName>{t(`games.tags.${tagName}`)}</S.TagName>
                        </If>
                    </S.TextWrapper>
                </S.Tag>
            );
        }
    }

    return (
        <>
            {jsx.length > 0 && (
                <S.TagsWrapper>{isGrid ? jsx[0] : jsx}</S.TagsWrapper>
            )}
        </>
    );
}
