import { ActionButtons } from 'components/ui/buttons';
import { MessageBoxWrapper } from 'components/ui/message-box';
import * as S from 'components/ui/message-box/MessageBox.style';
import { withInputDispatcherProvider } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { createPortal } from 'react-dom';

export const ConfirmationBox = withInputDispatcherProvider(
    ({
        IconComponent, // component
        title,
        message,
        message2,
        subtitle,
        children,
        buttons,
        blurOverlap,
        backPressCallback,
    }) => {
        if (backPressCallback) {
            useBackPress(backPressCallback);
        }

        // Note: nested backdrop-filters does not work
        return createPortal(
            <MessageBoxWrapper blurOverlap={blurOverlap}>
                <S.MessageWrapper>
                    <If condition={IconComponent}>
                        <S.Icon>
                            <IconComponent />
                        </S.Icon>
                    </If>
                    <If condition={title}>
                        <S.Title>{title}</S.Title>
                    </If>
                    <If condition={message}>
                        <S.Text>{message}</S.Text>
                    </If>
                    <If condition={message2}>
                        <S.Text>{message2}</S.Text>
                    </If>
                    {children}
                </S.MessageWrapper>
                <If condition={subtitle}>
                    <S.Subtitle>{subtitle}</S.Subtitle>
                </If>
                <If condition={!buttons.length}></If>
                <ActionButtons
                    focusOnMount
                    defaultElement
                    buttons={buttons}
                    style={{ marginTop: '1.5rem' }}
                />
            </MessageBoxWrapper>,
            document.body
        );
    }
);
ConfirmationBox.displayName = 'ConfirmationBox';
