import {
    DangerButton,
    NeutralButton,
    PrimaryButton,
    RegularButton,
} from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { forwardRef } from 'react';
import * as S from './ActionButtons.style';

// buttons is an array of
// {
//     isVisible: true|false, // true by default, set to false to omit button
//     type: 'regular'|'neutral'|'primary'|'danger', // standard button types
//     component: React.Component, // optional component to use if type is not specified - type has priority
//                                 // if neither type nor component is specified, the button is regular by default
//     text: '',
//     callback: () => {},
//     timer: null
//     props: {}, props for the button component
// }
//
// navSection: wrap the buttons inside a SpatialNavSection, default is true
// if true and no button has the defaultElement prop, the first button will be set as default
export const ActionButtons = forwardRef(
    (
        {
            buttons,
            Wrapper = S.Wrapper,
            wrapperProps = {},
            navSection = true,
            defaultElement,
            focusOnMount,
            enterTo = 'default-element',
            overrideMoveDown,
            ...props
        },
        forwardedRef
    ) => {
        // check if one button has the defaultElement prop
        // if none, the first button will be set as defaultElement
        const hasDefaultElement = buttons.some((b) => b?.defaultElement);

        const typedButton = {
            regular: RegularButton,
            neutral: NeutralButton,
            primary: PrimaryButton,
            danger: DangerButton,
        };

        const createButtonComponent = ({ type, component }) => {
            if (type) {
                const button = typedButton[type];
                if (!button) {
                    console.error('Unknown button type', type);
                }
                return button;
            } else if (component) {
                return component;
            } else {
                return typedButton.regular;
            }
        };

        const lastButtonIndex = buttons.reduce(
            (lastVisibleIndex, { isVisible = true }, index) =>
                isVisible ? index : lastVisibleIndex,
            -1
        );

        const content = (
            <Wrapper
                data-type="action-button-wrapper"
                ref={forwardedRef}
                {...wrapperProps}
                {...props}
            >
                {buttons.map(({ isVisible = true, ref, ...button }, i) => {
                    const ButtonComponent = createButtonComponent(button);

                    return (
                        <If condition={isVisible}>
                            <ButtonComponent
                                ref={ref}
                                data-type="action-button"
                                key={i}
                                defaultElement={
                                    navSection &&
                                    (button.defaultElement ||
                                        (i === 0 && !hasDefaultElement))
                                }
                                onClick={button.callback}
                                overrideMoveDown={() => {
                                    if (
                                        lastButtonIndex === i &&
                                        overrideMoveDown
                                    ) {
                                        overrideMoveDown();
                                    } else if (button.overrideMoveDown) {
                                        button.overrideMoveDown();
                                    } else {
                                        return true;
                                    }
                                }}
                                {...button.props}
                            >
                                <div>{button.text}</div>
                                {button.timer && (
                                    <S.Tick>{button.timer}s</S.Tick>
                                )}
                            </ButtonComponent>
                        </If>
                    );
                })}
            </Wrapper>
        );

        if (navSection) {
            return (
                <SpatialNavSection
                    defaultElement={defaultElement}
                    enterTo={enterTo}
                    focusOnMount={focusOnMount}
                >
                    {content}
                </SpatialNavSection>
            );
        }

        return content;
    }
);
ActionButtons.displayName = 'ActionButtons';
